import { useRef } from 'react';

import { IRefs, Navigation } from 'components/navigation';
import { Certificates } from 'components/sections/certificates';
import { Contact } from 'components/sections/contact/Contact';
import { Hero } from 'components/sections/hero';
import { Projects } from 'components/sections/projects';
import { Skills } from 'components/sections/skills';
import { useNavigation } from 'shared/hooks/useNavigation';

import './Feed.scss';

const Feed = () => {
  const refs: IRefs = {
    skills: useRef<null | HTMLElement>(null),
    projects: useRef<null | HTMLElement>(null),
    certs: useRef<null | HTMLElement>(null),
    contact: useRef<null | HTMLElement>(null),
  };

  const { goToElement } = useNavigation(refs);

  return (
    <div className="feed page">
      <Navigation onNavigate={goToElement} />
      <Hero />
      <Skills ref={refs.skills} />
      <Projects ref={refs.projects} />
      <Certificates ref={refs.certs} />
      <Contact ref={refs.contact} />
    </div>
  );
};

export { Feed };
