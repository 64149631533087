import { createSlice } from '@reduxjs/toolkit';

import { UiState } from 'store/ui';

const toastInitialState = {
  content: {
    message: '',
  },
  isOpen: false,
};

const initialState: UiState = {
  toast: toastInitialState,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    clearUiState: () => initialState,
  },
});

const uiReducer = uiSlice.reducer;

export { uiReducer };
