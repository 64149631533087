import classNames from 'classnames';
import { NavItem } from 'components/navigation';
import { useState } from 'react';
import { MdClose, MdOutlineApps } from 'react-icons/md';

import './Navigation.scss';

interface INavigationProps {
  onNavigate: (id: string) => void;
}

const navItems = [
  {
    title: 'Skills',
    id: 'skills',
  },
  {
    title: 'Work',
    id: 'projects',
  },
  {
    title: 'Certs',
    id: 'certs',
  },
  {
    title: 'Contact',
    id: 'contact',
  },
];

const Navigation = ({ onNavigate }: INavigationProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleActive = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleItemClick = (id: string) => {
    onNavigate(id);
    setIsExpanded(false);
  };

  return (
    <div
      className={classNames('navigation', {
        'navigation--expanded': isExpanded,
      })}
    >
      <div className="navigation__content-wrapper">
        <nav className="navigation__content">
          {navItems.map((data) => (
            <NavItem
              key={data.id}
              title={data.title}
              onClick={() => handleItemClick(data.id)}
            />
          ))}
        </nav>
      </div>

      <div className="navigation__action" onClick={toggleActive}>
        {isExpanded ? <MdClose style={{ opacity: 0.5 }} /> : <MdOutlineApps />}
      </div>
    </div>
  );
};

export { Navigation };
