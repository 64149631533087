import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { hero as data } from 'data/hero';
import { Avatar } from 'components';

import './Hero.scss';

const Hero = () => {
  const [heroAnim, setheroAnim] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      setheroAnim(true);
    }, 1250);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <section
      className={classNames('hero', {
        anim: heroAnim,
      })}
    >
      <div className="hero__col">
        <div className="hero__avatar">
          <Avatar />
        </div>
        <h1 className="hero__title">{data.title}</h1>
        <div className="hero__description">
          <p className="experience">{data.experience}</p>
          <p className="additional">{data.additional}</p>
        </div>
      </div>
    </section>
  );
};

export { Hero };
