import React from 'react';

import { Certificate } from 'components/sections/certificates';
import { certificates } from 'data/certificates';
import certificateIcon from 'assets/images/certificate-icon.svg';

import './Certificates.scss';

const Certificates = React.forwardRef((_, ref) => {
  if (!certificates?.length) return null;

  return (
    <section className="certificates" ref={ref as React.RefObject<HTMLElement>}>
      <img
        src={certificateIcon}
        className="certificates__icon"
        alt="certificate"
      />

      <ul className="certificates__list">
        {certificates.map((certificate) => (
          <Certificate {...certificate} key={certificate.title} />
        ))}
      </ul>
    </section>
  );
});

export { Certificates };
