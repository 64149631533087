import { ICertificate } from 'components/sections/certificates';

const certificates: ICertificate[] = [
  {
    description: 'The Complete Guide',
    link: 'https://www.udemy.com/certificate/UC-Q0S9DX92/',
    title: 'Angular',
    year: '2019',
  },
  {
    description: 'Angular, Node.js, Express, MongoDB',
    link: 'https://www.udemy.com/certificate/UC-NVK89GCI/',
    title: 'MEAN',
    year: '2019',
  },
  {
    description: 'incl MVC, REST API, GraphQL',
    link: 'https://www.udemy.com/certificate/UC-49e1eb39-41f1-4dd6-89ed-1bc51a7fc2e0/',
    title: 'Node.js',
    year: '2020',
  },
  {
    description: 'incl Hooks, React Router, Redux',
    link: 'https://www.udemy.com/certificate/UC-044c2110-0bf9-4839-abed-ee329de5426b/',
    title: 'React',
    year: '2020',
  },
  {
    description: 'React, Node.js, Express & MongoDB',
    link: 'https://www.udemy.com/certificate/UC-b46c519f-aff2-49dc-a5c1-51e16a01ebba/',
    title: 'MERN',
    year: '2020',
  },
  {
    description: 'Clean and maintainable code',
    link: 'https://www.udemy.com/certificate/UC-77b67029-4753-4db8-9226-3b78a4f42f4e/',
    title: 'Clean Code',
    year: '2021',
  },
  {
    description: 'TypeScript Deep Dive',
    link: 'https://www.udemy.com/certificate/UC-8831e53f-4dc8-4112-8692-c7d96ae1db46/',
    title: 'TypeScript',
    year: '2021',
  },
  {
    description: 'The Complete Guide',
    link: 'https://www.udemy.com/certificate/UC-f794e78a-1581-413e-8e04-2ed263621bff/',
    title: 'MongoDB',
    year: '2022',
  },
  {
    description: 'Serverless APIs & Apps',
    link: 'https://www.udemy.com/certificate/UC-9359420b-c44f-413a-b923-bf53fc7560ab/',
    title: 'AWS',
    year: '2023',
  },
];

export { certificates };
