import { createTheme } from '@mui/material/styles';

import colors from 'styles/_colors.scss';
import fonts from 'styles/_fonts.scss';

const typography = {
  fontFamily: fonts.fontFamily,
};

export const muiTheme = createTheme({
  typography,

  palette: {
    text: {
      primary: colors.textPrimary,
    },
    primary: {
      main: colors.primaryMain,
      light: colors.primaryLight,
      dark: colors.primaryDark,
    },
    error: {
      main: colors.errorMain,
      light: colors.errorLight,
      dark: colors.errorDark,
    },
  },
});
