import { Button, FormField, Loading, useForm } from 'shared';

import {
  IContactFormFields,
  FormProps,
  IFormInputValuesMap,
} from 'shared/types/form';

import './ContactForm.scss';

const ContactForm = ({
  fields,
  isLoading,
  submitBtnLabel,
  onSubmitted,
}: FormProps<IContactFormFields>) => {
  const { inputValues, handleInputChange, validateInputs, clearForm } =
    useForm(fields);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let isValid = validateInputs();
    if (!isValid) return;

    const prepareOutputData = (inputValues: IFormInputValuesMap) => {
      let result = {} as IContactFormFields;

      for (let name in inputValues) {
        const fieldData = inputValues[name];
        result = {
          ...result,
          [name]: fieldData.value,
        };
      }
      return result;
    };

    const outputData = prepareOutputData(inputValues);
    onSubmitted(outputData);
    clearForm();
  };

  const submitButtonEl = (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={isLoading}
      className="contact-form__button"
    >
      {submitBtnLabel}
    </Button>
  );

  return (
    <div className="contact-form__wrapper">
      <div className="contact-form__header">
        <h2>Interested in working together?</h2>
        <p>I'm excited to learn about your project</p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="contact-form"
        autoComplete="off"
        noValidate
      >
        <div className="contact-form__fields-wrapper">
          {fields.map((data) => (
            <FormField
              label={data.label}
              type={data.type}
              name={data.name}
              inputData={inputValues[data.name]}
              onChange={handleInputChange}
              fullWidth={true}
              multiline={data.multiline}
              minRows={data.multiline ? data.minRows : undefined}
              required={data.required ? data.required : false}
              key={data.name}
            />
          ))}
        </div>
        <div className="contact-form__buttons-wrapper">
          {isLoading ? <Loading /> : submitButtonEl}
        </div>
      </form>

      {/* {mailSent && <div className="sucsess">{successMessage}</div>}
              {error && <div className="error">{errorMessage}</div>} */}
    </div>
  );
};

export { ContactForm };
