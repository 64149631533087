import { ISkill } from 'components/sections/skills';

const skills: ISkill[] = [
  {
    title: 'Next.js, NextAuth',
    level: 1,
    groupId: 1,
  },
  {
    title: 'Node.js, Express, Nest.js',
    level: 1,
    groupId: 1,
  },
  {
    title: 'GCP, AWS, Azure, Heroku',
    level: 1,
    groupId: 1,
  },
  {
    title: 'MongoDB, Mongoose',
    level: 1,
    groupId: 1,
  },
  {
    title: 'Auth.js, Clerk',
    level: 1,
    groupId: 1,
  },

  {
    title: 'Docker, Compose',
    level: 1,
    groupId: 2,
  },
  {
    title: 'Bcrypt, Crypto.js, JWT',
    level: 1,
    groupId: 2,
  },
  {
    title: 'Alchemy, Thirdweb',
    level: 1,
    groupId: 2,
  },
  {
    title: 'Stripe, Alpaca, Plaid',
    level: 1,
    groupId: 2,
  },

  {
    title: 'TypeScript, JavaScript',
    level: 1,
    groupId: 3,
  },
  {
    title: 'React, React Router',
    level: 1,
    groupId: 3,
  },
  {
    title: 'Redux, Redux Toolkit',
    level: 1,
    groupId: 3,
  },
  {
    title: 'REST API, RTK Query',
    level: 1,
    groupId: 3,
  },
  {
    title: 'Axios, GraphQL',
    level: 1,
    groupId: 3,
  },

  {
    title: 'LangChain, OpenAI API',
    level: 1,
    groupId: 4,
  },
  {
    title: 'Gemini API, Google Colab',
    level: 1,
    groupId: 4,
  },
  {
    title: 'UI: Shadcn, Chakra, Material',
    level: 1,
    groupId: 4,
  },
  {
    title: 'Tailwind, SCSS',
    level: 1,
    groupId: 4,
  },
];

export { skills };
