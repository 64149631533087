import { Routes } from 'routes/Routes';

import './App.scss';

const App = () => {
  // const { Toast } = useToast();

  return <Routes />;
};

export { App };
