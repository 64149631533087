import { memo, useEffect, useRef, useState, TouchEvent } from 'react';

import { IAppViewItem } from 'components/sections/projects/projectTypes';

import './CarouselItem.scss';

interface ICarouselItemProps {
  item: IAppViewItem;
  type: 'prev' | 'current' | 'next';
  onClick?: () => void;
}
const CarouselItem = memo(({ item, type, onClick }: ICarouselItemProps) => {
  const [anim, setAnim] = useState(false);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  const minSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent) =>
    setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;

    // Handle left swipe
    if (distance > minSwipeDistance) {
      onClick && onClick();
    }
  };

  const src = item?.src;

  const timerRef: React.MutableRefObject<
    ReturnType<typeof setTimeout> | undefined
  > = useRef(undefined);

  const clearAnimationWithDelay = (delay: number) => {
    timerRef.current = setTimeout(() => {
      setAnim(false);
    }, delay);
  };

  useEffect(() => {
    if (src) {
      setAnim(true);
      clearAnimationWithDelay(600);
    }

    // Clear timer
    return () => timerRef.current && clearTimeout(timerRef.current);
  }, [src]);

  return (
    !!item && (
      <div
        className={`carousel-item carousel-item--${type} ${anim ? 'anim' : ''}`}
        onClick={() => onClick && onClick()}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <img src={item.src} alt={item.alt} />
      </div>
    )
  );
});

export { CarouselItem };
