import { IFormFieldData } from 'shared/types/form';

type ValidateInputArgs = {
  fieldData: IFormFieldData;
  regex: any;
  errMessage: string;
  keepCase?: boolean;
};

const getError = (args: ValidateInputArgs): string | null => {
  const { fieldData, regex, errMessage, keepCase } = args;
  const res = keepCase
    ? regex.test(fieldData.value)
    : regex.test(fieldData.value?.toLowerCase());
  return !res ? errMessage : null;
};

export const validateName = (fieldData: IFormFieldData): IFormFieldData => {
  const regex = /^[a-zA-Z]{2,20}$/;
  const errMessage = '2-20 symbols, only latin letters';
  return {
    ...fieldData,
    error: getError({ fieldData, regex, errMessage }),
  };
};

export const validateEmail = (fieldData: IFormFieldData): IFormFieldData => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const errMessage = 'Invalid Email';
  return {
    ...fieldData,
    error: getError({ fieldData, regex, errMessage }),
  };
};

export const validateMessage = (fieldData: IFormFieldData): IFormFieldData => {
  const regex = /^[a-zA-Z0-9 $.,-]{2,300}$/;
  const errMessage = '2-300 symbols, latin letters and digits';
  return {
    ...fieldData,
    error: getError({ fieldData, regex, errMessage }),
  };
};
