import './NavItem.scss';

interface INavItemProps {
  title: string;
  onClick: () => void;
}

const NavItem = ({ title, onClick }: INavItemProps) => {
  return (
    <div className="nav-item" onClick={onClick}>
      {title}
    </div>
  );
};

export { NavItem };
