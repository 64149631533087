import { ICertificate } from 'components/sections/certificates/certificateTypes';

import './Certificate.scss';

const Certificate = (certificate: ICertificate) => {
  return (
    <li className="certificate">
      <div className="certificate__year">{certificate.year}</div>
      {/* <a
        className="certificate__content"
        href={certificate.link}
        rel="noreferrer"
        target="_blank"
      > */}
      <div className="certificate__content">
        <div className="certificate__title">{certificate.title}</div>
        <div className="certificate__description">
          {certificate.description}
        </div>
      </div>
      {/* </a> */}
    </li>
  );
};

export { Certificate };
