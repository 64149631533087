import classNames from 'classnames';
import { TextField, TextFieldProps } from '@mui/material';

import { IFormFieldData } from '../../../types/form';

import './FormField.scss';

type FormFieldProps = TextFieldProps & {
  inputData: IFormFieldData;
};

const FormField = (props: FormFieldProps) => {
  const d = props.inputData;
  const err = d?.error != null;

  const textFieldEl = (
    <div className={classNames('form-field form-field--text', props.className)}>
      <TextField
        variant={props.variant}
        id={props.name}
        label={props.label}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        value={d.value}
        error={err}
        helperText={err && d.error}
        required={props.required}
        multiline={props.multiline}
        minRows={props.minRows}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
      />
    </div>
  );

  switch (props.type) {
    case 'text':
    case 'email':
      return textFieldEl;
    default:
      return null;
  }
};

export { FormField };
