import { IProject } from 'components/sections/projects/projectTypes';
import { Carousel } from 'shared/components/carousel/Carousel/Carousel';
import { TechStack } from 'shared/components/tech-stack/TechStack/TechStack';

import './Project.scss';

const Project = (project: IProject) => {
  const iconOpaciry = project.appIcon?.opacity;

  return (
    <div className="project">
      <div className="project__column app-views">
        <div className="project__app-view-container">
          <Carousel items={project.appViews} color={project.color} />
        </div>
      </div>

      <div className="project__column description">
        <div className="project__icon-title">
          <a
            className="project__link"
            href={project.appUrl}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="project__icon"
              style={iconOpaciry ? { opacity: iconOpaciry } : undefined}
            >
              <img
                srcSet={`${project.appIcon.imgSrc2x} 2x`}
                alt="dcode"
                src={project.appIcon.imgSrc}
              />
            </div>
            <h2 className="project__title">{project.title}</h2>
          </a>
        </div>
        <p className="project__description">{project.dscription}</p>
        <div className="project__stack">
          <TechStack items={project.stack} />
        </div>
      </div>
    </div>
  );
};

export { Project };
