import { IProject } from 'components/sections/projects/projectTypes';

// Threads
import threadsIcon from 'assets/images/apps/threads/icon-72x72.png';
import threadsIcon2x from 'assets/images/apps/threads/icon-144x144.png';
import threadsAppViewGroups from 'assets/images/apps/threads/threads-view_groups.jpg';
import threadsAppViewPeople from 'assets/images/apps/threads/threads-view_people.jpg';
import threadsAppViewComment from 'assets/images/apps/threads/threads-view_comment.jpg';

// HelpArm
import helparmIcon from 'assets/images/apps/helparm/icon-72x72.png';
import helparmIcon2x from 'assets/images/apps/helparm/icon-144x144.png';
import helparmAppViewMain1 from 'assets/images/apps/helparm/helparm-view_main-1.jpg';
import helparmAppViewMain2 from 'assets/images/apps/helparm/helparm-view_main-2.jpg';
import helparmAppViewSearch from 'assets/images/apps/helparm/helparm-view_search.jpg';
import helparmAppViewArticle from 'assets/images/apps/helparm/helparm-view_article.jpg';
import helparmAppViewOffers1 from 'assets/images/apps/helparm/helparm-view_offers-1.jpg';
import helparmAppViewOffers2 from 'assets/images/apps/helparm/helparm-view_offers-2.jpg';

// Tradey
import tradeyIcon from 'assets/images/apps/tradey/icon-72x72.png';
import tradeyIcon2x from 'assets/images/apps/tradey/icon-144x144.png';
import tradeyAppViewFeed from 'assets/images/apps/tradey/tradey-view_feed.jpg';
import tradeyAppViewAsset from 'assets/images/apps/tradey/tradey-view_asset.jpg';
import tradeyAppViewExplore from 'assets/images/apps/tradey/tradey-view_explore.jpg';
import tradeyAppViewProfile from 'assets/images/apps/tradey/tradey-view_profile.jpg';
import tradeyAppViewPopup from 'assets/images/apps/tradey/tradey-view_popup.jpg';

// Crypto
import cryptoIcon from 'assets/images/apps/crypto/icon-72x72.png';
import cryptoIcon2x from 'assets/images/apps/crypto/icon-144x144.png';
import cryptoAppViewFeed from 'assets/images/apps/crypto/crypto-view_feed.jpg';
import cryptoAppViewDetailsDay from 'assets/images/apps/crypto/crypto-view_details-day.jpg';
import cryptoAppViewDetailsYear from 'assets/images/apps/crypto/crypto-view_details-year.jpg';
import cryptoAppViewNews from 'assets/images/apps/crypto/crypto-view_news.jpg';

// Crowdfunding
import crowdfundingIcon from 'assets/images/apps/crowdfunding/icon-72x72.png';
import crowdfundingIcon2x from 'assets/images/apps/crowdfunding/icon-144x144.png';
import crowdfundingAppViewMain from 'assets/images/apps/crowdfunding/cf-view_main.jpg';
import crowdfundingAppViewDetails from 'assets/images/apps/crowdfunding/cf-view_details.jpg';
import crowdfundingAppViewFund from 'assets/images/apps/crowdfunding/cf-view_fund.jpg';
import crowdfundingAppViewCreate from 'assets/images/apps/crowdfunding/cf-view_create.jpg';

// DCode
import decodeIcon from 'assets/images/apps/dcode/icon-72x72.png';
import decodeIcon2x from 'assets/images/apps/dcode/icon-144x144.png';
import decodeAppViewAnim from 'assets/images/apps/dcode/dcode-view_anim.gif';
import decodeAppViewForm from 'assets/images/apps/dcode/dcode-view_form.jpg';
import decodeAppViewResult from 'assets/images/apps/dcode/dcode-view_result.jpg';

// Routes
import routesIcon from 'assets/images/apps/routes/icon-72x72.png';
import routesIcon2x from 'assets/images/apps/routes/icon-144x144.png';
import routesAppViewMain from 'assets/images/apps/routes/routes-app_main.jpg';
import routesAppViewRoute from 'assets/images/apps/routes/routes-app_route.jpg';
import routesAppViewDetails from 'assets/images/apps/routes/routes-app_details.jpg';
import routesAppViewCreate from 'assets/images/apps/routes/routes-app_create.jpg';

// Chat AI
import chataiIcon from 'assets/images/apps/chatai/icon-72x72.png';
import chataiIcon2x from 'assets/images/apps/chatai/icon-144x144.png';

import chataiAppViewChatDark from 'assets/images/apps/chatai/chatai-view_chat-dark.jpg';
import chataiAppViewNewChatDark from 'assets/images/apps/chatai/chatai-view_new-chat-dark.jpg';
import chataiAppViewEmptyChatDark from 'assets/images/apps/chatai/chatai-view_empty-chat-dark.jpg';
import chataiAppViewChatListLight from 'assets/images/apps/chatai/chatai-view_chat-list-light.jpg';
import chataiAppViewNewChatLight from 'assets/images/apps/chatai/chatai-view_new-chat-light.jpg';
import chataiAppViewEmptyChatLight from 'assets/images/apps/chatai/chatai-view_empty-chat-light.jpg';
import chataiAppViewChatLight from 'assets/images/apps/chatai/chatai-view_chat-light.jpg';

// Store
import storeIcon from 'assets/images/apps/store/icon-72x72.png';
import storeIcon2x from 'assets/images/apps/store/icon-144x144.png';
import storeAppViewMain from 'assets/images/apps/store/store-view_main.jpg';
import storeAppViewDetails from 'assets/images/apps/store/store-view_details.jpg';
import storeAppViewCart from 'assets/images/apps/store/store-view_cart.jpg';
import storeAppViewOrder from 'assets/images/apps/store/store-view_order.jpg';
import storeAppViewResult from 'assets/images/apps/store/store-view_result.jpg';

const projects: IProject[] = [
  // Chat AI
  {
    title: 'Chat AI',
    appIcon: {
      imgSrc: chataiIcon,
      imgSrc2x: chataiIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: chataiAppViewChatDark,
        alt: 'Chat AI new chat dark mode',
      },
      {
        id: '1',
        src: chataiAppViewNewChatDark,
        alt: 'Chat AI new chat screen dark mode',
      },
      {
        id: '2',
        src: chataiAppViewEmptyChatDark,
        alt: 'Chat AI new empty chat dark mode',
      },
      {
        id: '3',
        src: chataiAppViewChatListLight,
        alt: 'Chat AI chat list screen light mode',
      },
      {
        id: '4',
        src: chataiAppViewNewChatLight,
        alt: 'Chat AI new chat screen light mode',
      },
      {
        id: '5',
        src: chataiAppViewEmptyChatLight,
        alt: 'Chat AI new empty chat light mode',
      },
      {
        id: '6',
        src: chataiAppViewChatLight,
        alt: 'Chat AI new chat light mode',
      },
    ],
    appUrl: 'https://chatai-sigma-three.vercel.app/',
    color: '#8e1591',
    dscription:
      "Choose from a cast of colorful personalities and watch their expressions change with every chat message. See how they react to your conversation in real-time through the expressive avatars! It's chat with a splash of personality!",
    stack: [
      'Typescript',
      'Next.js',
      'NextAuth.js',
      'Langchain',
      'Google Generative AI',
      'Mongoose',
      'JWT',
      'Nodemailer',
      'React Hook Form',
      'Tailwind CSS',
      'Shadcn UI',
      'Zod',
    ],
  },
  // HelpArm
  {
    title: 'HelpArm',
    appIcon: {
      imgSrc: helparmIcon,
      imgSrc2x: helparmIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: helparmAppViewMain1,
        alt: 'HelpArm main screen',
      },
      {
        id: '1',
        src: helparmAppViewMain2,
        alt: 'HelpArm main screen',
      },
      {
        id: '2',
        src: helparmAppViewSearch,
        alt: 'HelpArm search screen',
      },
      {
        id: '3',
        src: helparmAppViewArticle,
        alt: 'HelpArm article screen',
      },
      {
        id: '4',
        src: helparmAppViewOffers1,
        alt: 'HelpArm offers dark mode screen',
      },
      {
        id: '5',
        src: helparmAppViewOffers2,
        alt: 'HelpArm offers light mode screen',
      },
    ],
    appUrl: 'https://help.endeveron.com',
    color: '#007389',
    dscription:
      'The web portal of assistance to Ukrainians in Poland that provides various services to help Ukrainians living or working in Poland. Users can access information about apartment, transport, financial, legal support, education, health care and more.',
    stack: [
      'Typescript',
      'React',
      'React Router',
      'Redux',
      'Redux Persist',
      'Express',
      'Axios',
      'Bcrypt',
      'JWT',
      'Mongoose',
      'Yup',
      'React Hook Form',
      'Chakra UI',
      'Sass',
      'Moment',
      'Jsdom',
    ],
  },
  // Threads
  {
    title: 'Threads',
    appIcon: {
      imgSrc: threadsIcon,
      imgSrc2x: threadsIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: threadsAppViewGroups,
        alt: 'Threads groups screen',
      },
      {
        id: '1',
        src: threadsAppViewPeople,
        alt: 'Threads people screen',
      },
      {
        id: '2',
        src: threadsAppViewComment,
        alt: 'Threads comment screen',
      },
    ],
    appUrl: 'https://threads-eight-tan.vercel.app',
    color: '#bf185d',
    dscription:
      'An web app where you can view and share public conversations, create new communities and invite others. Authentication using Clerk, commenting system with nested threads provides a structured conversation flow.',
    stack: [
      'Typescript',
      'Next.js',
      'Clerk',
      'Mongoose',
      'React Hook Form',
      'Uploadthing',
      'Shadcn UI',
      'Moment',
    ],
  },
  // Tradey
  {
    title: 'Tradey',
    appIcon: {
      imgSrc: tradeyIcon,
      imgSrc2x: tradeyIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: tradeyAppViewFeed,
        alt: 'Tradey feed screen',
      },
      {
        id: '1',
        src: tradeyAppViewAsset,
        alt: 'Tradey asset screen',
      },
      {
        id: '2',
        src: tradeyAppViewExplore,
        alt: 'Tradey explore screen',
      },
      {
        id: '3',
        src: tradeyAppViewProfile,
        alt: 'Tradey user screen',
      },
      {
        id: '4',
        src: tradeyAppViewPopup,
        alt: 'Tradey popup screen',
      },
    ],
    appUrl: 'https://tradey.endeveron.com',
    color: '#56299a',
    dscription:
      'Social platform for investors and traders. See verified trading activity and results of top traders, talk about stocks, and research reliable experts. Take advantage of our referral programs and subscribe to email updates.',
    stack: [
      'React',
      'React Router',
      'Redux',
      'Redux Persist',
      'Express',
      'Axios',
      'Socket.io',
      'Bcrypt',
      'JWT',
      'Mongoose',
      'Sass',
      'Material UI',
      'Alpaca',
      'Plaid',
      'Front',
      'Nodemailer',
    ],
  },
  // Crowdfunding
  {
    title: 'Crowdfunding',
    appIcon: {
      imgSrc: crowdfundingIcon,
      imgSrc2x: crowdfundingIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: crowdfundingAppViewMain,
        alt: 'crowdfunding main screen',
      },
      {
        id: '1',
        src: crowdfundingAppViewDetails,
        alt: 'crowdfunding details screen',
      },
      {
        id: '2',
        src: crowdfundingAppViewFund,
        alt: 'crowdfunding fund screen',
      },
      {
        id: '3',
        src: crowdfundingAppViewCreate,
        alt: 'crowdfunding new campagny screen',
      },
    ],
    appUrl: 'https://cf.endeveron.com',
    color: '#177941',
    dscription:
      'Get your projects funded in no time with the power of blockchain technology. This crowdfunding platform connects creators with a global network of investors, allowing them to raise funds in a decentralized, transparent, and secure way.',
    stack: [
      'Ethers',
      'Thitdweb',
      'React',
      'Typescript',
      'React Router',
      'Redux',
      'Redux Persist',
      'RTK Query',
      'Express',
      'JWT',
      'Mongoose',
      'Crypto.js',
      'Bcrypt',
      'Hardhat',
      'Sass',
      'Material UI',
      'PWA',
    ],
  },
  // // ChatAI
  // {
  //   title: 'ChatAI',
  //   appIcon: {
  //     imgSrc: chataiIcon,
  //     imgSrc2x: chataiIcon2x,
  //   },
  //   appViews: [
  //     {
  //       id: '0',
  //       src: chataiAppViewMain,
  //       alt: 'chatai main screen',
  //     },
  //     {
  //       id: '1',
  //       src: chataiAppViewChat,
  //       alt: 'chatai chat screen',
  //     },
  //     {
  //       id: '2',
  //       src: chataiAppViewSidebar,
  //       alt: 'chatai sidebar',
  //     },
  //   ],
  //   appUrl: 'https://chatai.endeveron.com',
  //   color: '#525265',
  //   dscription:
  //     'An AI-powered conversational tool that can understand and respond to natural language input using the OpenAI API. You can chat in different contexts by selecting a specific preset model.',
  //   stack: [
  //     'OpenAI',
  //     'React',
  //     'Typescript',
  //     'React Router',
  //     'Redux',
  //     'Redux Persist',
  //     'RTK Query',
  //     'Express',
  //     'JWT',
  //     'Mongoose',
  //     'Bcrypt',
  //     'Sass',
  //     'Material UI',
  //     'PWA',
  //   ],
  // },
  // Store
  {
    title: 'Store',
    appIcon: {
      imgSrc: storeIcon,
      imgSrc2x: storeIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: storeAppViewMain,
        alt: 'Store main screen',
      },
      {
        id: '1',
        src: storeAppViewDetails,
        alt: 'Store product details screen',
      },
      {
        id: '2',
        src: storeAppViewCart,
        alt: 'Store cart screen',
      },
      {
        id: '3',
        src: storeAppViewOrder,
        alt: 'Store order screen',
      },
      {
        id: '4',
        src: storeAppViewResult,
        alt: 'Store order result screen',
      },
    ],
    appUrl: 'https://store.endeveron.com',
    color: '#7c0974',
    dscription:
      'Browse through our wide range of gadgets, from the latest smartphones to cutting-edge taablets and laptops. Stay updated with our regular offers and never miss out on the latest tech trends.',
    stack: [
      'React',
      'Typescript',
      'React Router',
      'Redux',
      'Redux Persist',
      'RTK Query',
      'Express',
      'JWT',
      'Mongoose',
      'Crypto.js',
      'Sass',
      'Material UI',
    ],
  },
  // Crypto
  {
    title: 'Crypto',
    appIcon: {
      imgSrc: cryptoIcon,
      imgSrc2x: cryptoIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: cryptoAppViewFeed,
        alt: 'Crypto feed screen',
      },
      {
        id: '1',
        src: cryptoAppViewDetailsDay,
        alt: 'Crypto result screen',
      },
      {
        id: '2',
        src: cryptoAppViewDetailsYear,
        alt: 'Crypto result screen',
      },
      {
        id: '3',
        src: cryptoAppViewNews,
        alt: 'Crypto news screen',
      },
    ],
    appUrl: 'https://crypto.endeveron.com',
    color: '#3b3b9f',
    dscription:
      'Access the most popular cryptocurrency values in real time, as well as the most recent news and articles, all in one spot. Make wise investing selections by staying current with market developments.',
    stack: [
      'React',
      'Typescript',
      'React Router',
      'Redux',
      'Redux Persist',
      'RTK Query',
      'Express',
      'JWT',
      'Mongoose',
      'Crypto.js',
      'Sass',
      'Material UI',
      'PWA',
    ],
  },
  // Routes
  {
    title: 'Routes',
    appIcon: {
      imgSrc: routesIcon,
      imgSrc2x: routesIcon2x,
      opacity: 0.85,
    },
    appViews: [
      {
        id: '0',
        src: routesAppViewMain,
        alt: 'Routes for passengers main screen',
      },
      {
        id: '1',
        src: routesAppViewRoute,
        alt: 'Routes for passengers details screen',
      },
      {
        id: '2',
        src: routesAppViewDetails,
        alt: 'Routes for driver main screen',
      },
      {
        id: '3',
        src: routesAppViewCreate,
        alt: 'Routes for driver create a route',
      },
    ],
    appUrl: 'https://routes.endeveron.com',
    color: '#2d4d5b',
    dscription:
      "A web platform connects drivers and passengers. Book seats as a passenger, or create routes as a driver with ease. Whether you're planning a trip or looking to earn extra income, this app makes it easy to find a ride or offer one.",
    stack: [
      'React',
      'React Router',
      'Express',
      'Socket.io',
      'Bcrypt',
      'JWT',
      'Mongoose',
      'Sass',
      'Material UI',
    ],
  },
  // DCode
  {
    title: 'DCode',
    appIcon: {
      imgSrc: decodeIcon,
      imgSrc2x: decodeIcon2x,
    },
    appViews: [
      {
        id: '0',
        src: decodeAppViewAnim,
        alt: 'DCode screen animation',
      },
      {
        id: '1',
        src: decodeAppViewForm,
        alt: 'DCode form screen',
      },
      {
        id: '2',
        src: decodeAppViewResult,
        alt: 'DCode result screen',
      },
    ],
    appUrl: 'https://dcode.endeveron.com',
    color: '#cb9b6c',
    dscription:
      "This app converts simple catchwords into strong passwords to enhance your privacy. Don't rely on vulnerable online password storage - take control of your sensitive data. Secure cryptographic algorithms are used.",
    stack: [
      'React',
      'Typescript',
      'React Router',
      'Redux',
      'Redux Persist',
      'RTK Query',
      'Express',
      'JWT',
      'Mongoose',
      'Crypto.js',
      'Sass',
      'Material UI',
    ],
  },
];

export { projects };
