import { IRefs } from 'components/navigation';

const useNavigation = (refs: IRefs) => {
  const goToElement = (id: string) => {
    refs[id].current?.scrollIntoView({ behavior: 'smooth' });
  };
  return { goToElement };
};

export { useNavigation };
