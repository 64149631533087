import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  Navigate,
} from 'react-router-dom';

import { Feed } from 'components/pages/Feed/Feed';

const Routes = () => (
  <BrowserRouter>
    <ReactRoutes>
      <Route path="/" element={<Feed />} />
      <Route path="*" element={<Navigate to="/" />} />
    </ReactRoutes>
  </BrowserRouter>
);

export { Routes };
