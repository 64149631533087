import React from 'react';

import { Skill, ISkill } from 'components/sections/skills';
import { skills } from 'data/skills';

import './Skills.scss';

interface ISkillGroup {
  id: number;
  skills: ISkill[];
}

const Skills = React.forwardRef((_, ref) => {
  if (!skills?.length) return null;

  const initSkillGroups = () => {
    const skillGroups: ISkillGroup[] = [];

    // split skills to groups
    for (let skill of skills) {
      const groupId = skill.groupId;
      const groupIndex = skillGroups.findIndex((group) => group.id === groupId);
      if (groupIndex < 0) {
        // Create a group
        const group = {
          id: groupId,
          skills: [skill],
        };
        skillGroups.push(group);
      } else {
        // Add skill to group
        const group = { ...skillGroups[groupIndex] };
        group.skills.push(skill);
        skillGroups[groupIndex] = group;
      }
    }

    return skillGroups.map((group) => (
      <div className="skills__group" key={group.id}>
        {group.skills.map((skill) => (
          <Skill {...skill} key={skill.title} />
        ))}
      </div>
    ));
  };

  const skillGroupsEl = initSkillGroups();

  return (
    <section className="skills" ref={ref as React.RefObject<HTMLElement>}>
      <div className="skills__group-wrapper">{skillGroupsEl}</div>
    </section>
  );
});

export { Skills };
