import React, { useState } from 'react';
import axios from 'axios';

import { ContactForm } from 'components/contact-form/ContactForm';
import { IContactFormFields, IFormField } from 'shared/types/form';
import {
  validateEmail,
  validateName,
  validateMessage,
} from 'shared/utils/validate';

import './Contact.scss';

const contactFormFields: IFormField[] = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    validator: validateName,
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    required: true,
    validator: validateEmail,
  },
  {
    name: 'message',
    label: 'Message',
    type: 'text',
    multiline: true,
    minRows: 7,
    required: true,
    validator: validateMessage,
  },
];

const Contact = React.forwardRef((_, ref) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (formData: IContactFormFields) => {
    try {
      setIsLoading(true);
      const result = await axios({
        method: 'post',
        url: 'https://endeveron.com/php/index.php',
        headers: { 'content-type': 'application/json' },
        data: formData,
      });

      if (result.data.sent) {
        console.log('Success');
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="contact" ref={ref as React.RefObject<HTMLElement>}>
      <ContactForm
        fields={contactFormFields}
        isLoading={isLoading}
        submitBtnLabel="Send"
        onSubmitted={handleSubmit}
      />
    </section>
  );
});

export { Contact };
