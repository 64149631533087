import './TechStack.scss';

interface TechStackProps {
  items: string[];
}

const TechStack = ({ items = [] }: TechStackProps) => {
  return (
    <div className="tech-stack">
      {/* <div className="tech-stack__title">Tech stack</div> */}
      <div className="tech-stack__list">
        {items.map((item) => (
          <div className="tech-stack__item" key={item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export { TechStack };
