import { ISkill } from 'components/sections/skills/skillTypes';

import './Skill.scss';

const Skill = (skill: ISkill) => {
  return (
    <div className="skill">
      <div className="skill__title">{skill.title}</div>
    </div>
  );
};

export { Skill };
