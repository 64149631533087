import React from 'react';
import { projects as data } from 'data/projects';
import { Project } from 'components/sections/projects/Project/Project';

import './Projects.scss';

const Projects = React.forwardRef((_, ref) => {
  return (
    <section className="projects" ref={ref as React.RefObject<HTMLElement>}>
      {data.map((project) => (
        <Project {...project} key={project.title} />
      ))}
    </section>
  );
});

export { Projects };
