import { useEffect, useState } from 'react';

import { IAppViewItem } from 'components/sections/projects/projectTypes';
import { CarouselItem } from 'shared/components/carousel/CarouselItem/CarouselItem';

import './Carousel.scss';

interface ICarousel {
  items: IAppViewItem[];
  color: string;
}

const Carousel = ({ items, color }: ICarousel) => {
  const [itemIdArr, setItemIdArr] = useState<string[]>([]);

  const curItem = items[+itemIdArr[1]];
  const prevItem = items[+itemIdArr[0]];
  const nextItem = items[+itemIdArr[2]];

  const unshiftArray = (arr: string[]) => {
    const lastElId = arr[arr.length - 1];
    const updArr = arr.slice(0, -1);
    updArr.unshift(lastElId);
    return updArr;
  };

  // Init item id array
  useEffect(() => {
    const initialItemIdArr = items.map((item) => item.id);
    const unshiftedItemIdArr = unshiftArray(initialItemIdArr);
    setItemIdArr(unshiftedItemIdArr);
  }, [items]);

  // // Unshift array [n, a, b, c, d, ..., n - 1]
  // const handleClickPrev = () => {
  //   const unshiftedItemIdArr = unshiftArray(itemIdArr);
  //   setItemIdArr(unshiftedItemIdArr);
  // };

  // Shift array [b, c, d, e, ... a]
  const handleClickNext = () => {
    const shiftedItemIdArr = [...itemIdArr.slice(1), itemIdArr[0]];
    setItemIdArr(shiftedItemIdArr);
  };

  return (
    <div className="carousel">
      <div className="carousel__container">
        <CarouselItem item={curItem} type="current" onClick={handleClickNext} />

        <div
          className="carousel__items-container"
          style={{ backgroundColor: color }}
        >
          <CarouselItem item={prevItem} type="prev" />
          <CarouselItem item={nextItem} type="next" />
        </div>
        <div className="carousel__bg" style={{ backgroundColor: color }}></div>
      </div>
    </div>
  );
};

export { Carousel };
